




























































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { gsap } from "gsap";

const contactModule = namespace("contact");

@Component
export default class Restoration extends Vue {
  @contactModule.Getter iainPhoneNumber!: string;

  displayContent = gsap.timeline();
  animate() {
    this.displayContent.play();
  }
  mounted() {
    this.displayContent.from("main .content", {
      duration: 0.5,
      opacity: 0,
    });
    this.animate();
  }
}
